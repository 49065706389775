import { Card, Grid, InputAdornment, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import LoadingData from '../../../components/LoadingData';
import CalendarioAcompanhamentoServices from '../CalendarioAcompanhamentoServices';
import ListaEscolas from './ListaEscolas';
import HeaderGlobal from '../../../components/header/HeaderGlobal';
import { ROLES } from '../../../global/constants';
import AbortController from './AbortController';
import AutoCompleteCustom from '../../../components/AutoCompleteCustom';
import Descritores from '../../../components/AutoCompletes/Descritores';

const Painel = () => {
    const {
        turnos,
        status,
        escolas,
        modelos,
        superintencias,
        tecnicos
    } = useSelector(state => state.acompanhamento)

    const {
        anoSelecionado,
        user
    } = useSelector(state => state.global)

    const [filtroEscolas, setFiltroEscolas] = useState('')
    const [gre, setGre] = useState(null)
    const [descritor, setDescritor] = useState(null)
    const dispatch = useDispatch()

    useEffect(() => {
        CalendarioAcompanhamentoServices.buscaStatusGerais(dispatch)
        CalendarioAcompanhamentoServices.buscaSuperintencias(dispatch, { use_loja: 1, com_escolas: 1, status: 1 })
    }, [])
    useEffect(() => {
        if (superintencias?.dados && superintencias.dados.length === 1) {
            setGre(superintencias.dados[0])
        }
    }, [superintencias])

    useEffect(() => {
        if (anoSelecionado) {
            CalendarioAcompanhamentoServices.buscaTurnosGerais(dispatch, { ano: anoSelecionado, })
            CalendarioAcompanhamentoServices.buscaEscolasDaRede(dispatch, { ano: anoSelecionado })
            CalendarioAcompanhamentoServices.buscarTecnicosGerais(dispatch, { ano: anoSelecionado, somente_escolas: 1, renew_cache: 1, limit: 0, inc_nome: 1 })
        }
    }, [anoSelecionado])

    useEffect(() => {
        if (anoSelecionado) {
            let params = { ano: anoSelecionado, somente_escolas: 1, renew_cache: 1, }
            if (descritor) {
                params['descritor_id'] = descritor.id
            }
            CalendarioAcompanhamentoServices.buscaCalendarios(dispatch, params)
        }
    }, [descritor, anoSelecionado])

    const renderLoading = () => {
        return (
            <>
                {turnos && <LoadingData obj={turnos} msg={"Buscando turnos..."} />}
                {status && <LoadingData obj={status} msg={"Buscando status..."} />}
                {escolas && <LoadingData obj={escolas} msg={"Buscando escolas..."} />}
                {modelos && <LoadingData obj={modelos} msg={"Buscando calendários..."} />}
                {tecnicos && <LoadingData obj={tecnicos} msg={"Buscando técnicos..."} />}
            </>
        )
    }

    const dadosCompletos = turnos?.dados && status?.dados && escolas?.dados && modelos?.dados && tecnicos?.dados;

    return (
        <>
            <AbortController />
            <Grid container style={{ width: '100%' }} >
                <Grid item xs={12}>
                    <HeaderGlobal titulo={'Acompanhamento calendário'} />
                </Grid>
                <Grid item xs={12}>
                    <Card style={{ padding: 10, marginBottom: 30 }} >
                            <>
                                <Grid container spacing={2}>
                                    {
                                        user.perfil === ROLES.PERFIL_GESTOR &&
                                        <>
                                            <Grid item md={6} lg={4} style={{ width: '100%' }}>
                                                {/* to do: criar um componente separado para o select de gre */}
                                                <AutoCompleteCustom
                                                    disabled={!dadosCompletos}
                                                    objResquest={superintencias}
                                                    label={`Selecione a ${user.empresa.nome_sup_singular}`}
                                                    list={superintencias?.dados || []}
                                                    noOptionsText={`Nenhuma ${user.empresa.nome_sup_singular} encontrada`}
                                                    onChange={setGre}
                                                    placeholder={'Selecione'}
                                                    value={gre ?? null}
                                                />
                                            </Grid>
                                            <Grid item md={6} lg={4} style={{ width: '100%' }}>
                                                <Descritores setValue={setDescritor} value={descritor} disabled={!dadosCompletos}/>
                                            </Grid>
                                        </>
                                    }
                                    <Grid item lg={user.perfil === ROLES.PERFIL_GESTOR ? 4 : 12} style={{ width: '100%' }}>
                                        <TextField
                                            disabled={!dadosCompletos}
                                            fullWidth
                                            size="small"
                                            id="textfield-filtrar-escola"
                                            label={"Buscar escolas"}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <FaSearch size={18} color={"#ccc"} />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            value={filtroEscolas}
                                            onChange={(e) => setFiltroEscolas(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                {renderLoading()}
                                {dadosCompletos && <ListaEscolas
                                    escolaSearch={filtroEscolas}
                                    greSelecionado={gre?.id}
                                    descritorSelecionado={descritor}
                                />}
                            </>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}

export default Painel;