import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AutoCompleteCustom from "../AutoCompleteCustom";
import GlobalService from "../../global/GlobalService";

const Descritores = ({ setValue, value, disabled }) => {

    const dispatch = useDispatch()

    const {
        anoSelecionado,
        user,
        descritores
    } = useSelector(state => state.global);

    useEffect(() => {
        if (anoSelecionado) {
            GlobalService.buscaDescritores(dispatch, { empresa_id: user.empresa.id, ano: anoSelecionado });
        }
    }, [anoSelecionado])

    return (
        <AutoCompleteCustom
            disabled={disabled}
            label={'Selecione o descritor'}
            objResquest={descritores}
            list={descritores?.dados || []}
            noOptionsText={'Descritor não encontrado'}
            onChange={setValue}
            placeholder={'Selecione'}
            value={value ?? null}
            campo={'descricao'}
        />
    )
}
export default Descritores;