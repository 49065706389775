import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Grid,
    Box,
    TextField,
    styled,
    InputAdornment
} from '@mui/material/';
import { FaSearch } from 'react-icons/fa';
import { ROLES } from "../../../global/constants";
import Utils from "../../../utils/Uitls";
import { useEffect } from "react";
import AutoCompleteCustom from "../../../components/AutoCompleteCustom";
import Descritores from "../../../components/AutoCompletes/Descritores";

const Input = styled(TextField)`
  background: #fff;
  border-radius: 4px;
`;

const headersTitle = ['GRE', 'Escola', 'INEP', 'Cidade', 'Status', 'Semestre I', 'Semestre II', 'Total de dias letivos']

const TabelaEscolasSemestres = ({action = () => {}}) => {

    const { user } = useSelector(state => state.global)
    const { dadosRelatorio, superintencias } = useSelector(state => state.relatorioGre)

    const [siSelecionada, setSiSelecionada] = useState(null)
    const [filtroEscolas, setFiltroEscolas] = useState('')
    const [limitePaginacao, setLimitePaginacao] = useState(30)
    const [descritor, setDescritor] = useState(null)

    const dadosCompletos = !!dadosRelatorio?.dados

    const filtroEscola = (isCsv = false) => {
        return dadosRelatorio?.dados?.filter(el => (!siSelecionada || el.si_id + "" === siSelecionada.id + "") &&
            (!filtroEscolas || el.escola.toLowerCase().includes(filtroEscolas)))
            .slice(0, isCsv ? dadosRelatorio?.dados?.length : limitePaginacao)
    }

    const listaEscolas = useMemo(() => {
        return filtroEscola(false)
    }, [siSelecionada, filtroEscolas, dadosCompletos])

    const handleGerarCsv = () => {

        const now = new Date();
        const serial = {
            month: now.getMonth(),
            day: now.getDay(),
            hour: now.getHours(),
            minutes: now.getMinutes(),
            seconds: now.getSeconds()
        }

        const fileName = `Relatorio_Periodos_Escolas_${serial.month}${serial.day}${serial.hour}${serial.minutes}${serial.seconds}`;
        const headers = ['gre', 'escola', 'inep', 'cidade', 'status', 'semestre1', 'semestre2'];
        const list = filtroEscola(true).map(el => ({
            gre: el.gre,
            escola: el.escola,
            inep: el.inep,
            cidade: el.cidade,
            status: el.status,
            semestre1: `${Utils.formatDataString(el.ini1)} - ${Utils.formatDataString(el.fim1)}`,
            semestre2: `${Utils.formatDataString(el.ini2)} - ${Utils.formatDataString(el.fim2)}`
        }));

        Utils.exportCsv(list, headers, fileName);
    }

    useEffect(() => {
        if(descritor){
            action(descritor.id)
        }
    }, [descritor])

    return (
        <>
            <Grid container spacing={1}>
                {
                    user.perfil === ROLES.PERFIL_GESTOR && <>
                        <Grid item md={6} lg={4} style={{ width: '100%' }}>
                            {/* to do: criar um componente separado para o select de gre */}
                            <AutoCompleteCustom
                                disabled={!dadosCompletos}
                                objResquest={superintencias}
                                label={`Selecione a ${user.empresa.nome_sup_singular}`}
                                list={superintencias?.dados || []}
                                noOptionsText={`Nenhuma ${user.empresa.nome_sup_singular} encontrada`}
                                onChange={setSiSelecionada}
                                placeholder={'Selecione'}
                                value={siSelecionada ?? null}
                            />
                        </Grid>
                        <Grid item md={6} lg={4} style={{ width: '100%' }}>
                            <Descritores setValue={setDescritor} value={descritor} disabled={!dadosCompletos}/>
                        </Grid>
                    </>
                }
                <Grid item lg={user.perfil === ROLES.PERFIL_GESTOR ? 4 : 12} style={{ width: '100%' }}>
                    <TextField
                        disabled={!dadosCompletos}
                        fullWidth
                        size="small"
                        id="textfield-filtrar-escola"
                        label={"Buscar escolas"}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <FaSearch size={18} color={"#ccc"} />
                                </InputAdornment>
                            ),
                        }}
                        value={filtroEscolas}
                        onChange={(e) => setFiltroEscolas(e.target.value)}
                    />
                </Grid>
                <Grid container item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 1 }}>
                    <Grid item >
                        <Button
                            disabled={!dadosCompletos}
                            variant="contained"
                            onClick={handleGerarCsv}
                        >
                            Gerar CSV
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            {dadosCompletos && <Paper sx={{ marginBottom: '50px', paddingBottom: '10px' }}>
                <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {
                                    headersTitle.map((el, i) =>
                                        <TableCell key={i} align="center">{el}</TableCell>
                                    )
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listaEscolas && listaEscolas.map((item) => (
                                <TableRow key={item.escola_id}>
                                    <TableCell align="left"> {item.gre} </TableCell>
                                    <TableCell align="left"> {item.escola} </TableCell>
                                    <TableCell align="center"> {item.inep} </TableCell>
                                    <TableCell align="left"> {item.cidade} </TableCell>
                                    <TableCell align="center"> {item.status} </TableCell>
                                    <TableCell align="center"> {Utils.formatDataString(item.ini1)} - {Utils.formatDataString(item.fim1)} </TableCell>
                                    <TableCell align="center"> {Utils.formatDataString(item.ini2)} - {Utils.formatDataString(item.fim2)} </TableCell>
                                    <TableCell align="center"> {item.total} </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {listaEscolas && limitePaginacao <= listaEscolas.length &&
                    <Box sx={{ textAlign: "center", marginTop: '10px' }}>
                        <Button
                            onClick={() => setLimitePaginacao((li => li + 30))}
                            variant="contained"
                            style={{ width: 200 }}
                        >
                            VER MAIS
                        </Button>

                    </Box>
                }
            </Paper>}
        </>
    )
}

export default TabelaEscolasSemestres;